import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@ui/components/ui/alert-dialog'
import { machineAlarms } from '@uv/machine'
import { LoaderCircleIcon, TriangleAlert } from 'lucide-react'
import { useDataStore } from '@/lib/machine/use-data-store'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { scaleClamp } from '@/lib/utils'
import { Gauge } from '@/components/ui/gauge'
import { useTranslation } from 'react-i18next'
import { formatAlarmName, formatAlarmTranslationBaseKey } from '../utils/format-alarm-name'
import { Link } from 'react-router-dom'
import { useLongPress } from '@uidotdev/usehooks'
import { Card, CardContent, CardHeader, CardTitle } from '@ui/components/ui/card'
import { cn } from '@ui/utils'

export const Alert = () => {
  const { t } = useTranslation()
  const activeInstallationStage = useDataStoreLocalStorage(s => s.activeInstallationStage)
  const dismissedAlarms = useDataStore(s => s.dismissedAlarms)
  const setDismissedAlarms = useDataStore(s => s.setDismissedAlarms)
  const refreshData = useDataStore(s => s.refreshData)
  const isConnected = useDataStore(s => s.isConnected)
  const sendMachineAction = useDataStore(s => s.sendMachineAction)
  const setDevMode = useDataStore(s => s.setDevMode)

  const ALARMS = refreshData?.ALARMS ?? []
  const alarms = ALARMS.map((alarm, i) => (alarm ? machineAlarms[i] : undefined)).filter(
    a => a !== undefined
  )

  const ESTOP_OK = refreshData?.ESTOP_OK ?? true

  const unhandledAlarms =
    activeInstallationStage === 'inversion'
      ? alarms.filter(a => !dismissedAlarms.includes(a)).filter(a => a !== 'PRESSURE')
      : activeInstallationStage === 'curing'
        ? alarms.filter(a => !dismissedAlarms.includes(a))
        : []

  const showMachineConnectionAlert = !isConnected
  const showEmergencyStopAlert = ESTOP_OK === false
  const showAlarmAlert = unhandledAlarms.length > 0

  // WIFI
  // Connection Strength
  // Anything below 75% should be considered a warning
  // Around 50-60% watchdog functionality will be compromised
  const showWeakConnectionAlert =
    !dismissedAlarms.includes('WIFI') && (refreshData ? refreshData.WIFI < 75 : false)
  const connectionStrength = 100 - scaleClamp(refreshData?.WIFI ?? 100, [50, 100], [0, 100])

  // Show dev bar
  const onLongPress = () => setDevMode(true)
  const longPressProps = useLongPress(onLongPress, {
    threshold: 2000
  })

  if (showWeakConnectionAlert) {
    return (
      <AlertDialog open={showWeakConnectionAlert}>
        <AlertDialogContent className="max-h-1/2 p-16">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-3xl mb-4 text-center select-none" {...longPressProps}>
              {t('installation.alert-weak-connection.title')}
            </AlertDialogTitle>

            <div className="relative flex justify-center pt-10 pb-12">
              <Gauge
                percentage={connectionStrength < 1 ? 1 : connectionStrength}
                arcRange={120}
                strokeWidth={4}
                className="absolute w-60 text-accent"
                gradientStops={[
                  { offset: '0%', stopColor: '#eb0000' },
                  { offset: '50%', stopColor: 'orange' },
                  { offset: '100%', stopColor: '#00bd3f' }
                ]}
              />

              <div className="mt-12 flex gap-2 items-baseline">
                <span className="text-lg font-bold text-zinc-700 uppercase">Signal</span>
              </div>
            </div>

            <AlertDialogDescription className="text-xl tight text-center">
              The machine connection signal is weak.
              {t('installation.alert-weak-connection.text1')}
              <br />
              {t('installation.alert-weak-connection.text2')}
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter className="self-end gap-4 mt-8">
            <AlertDialogCancel
              className="w-full p-8 text-md font-bold rounded-xl"
              onClick={() =>
                setDismissedAlarms([...new Set([...dismissedAlarms, 'WIFI' as const])])
              }
            >
              {t('installation.alert-weak-connection.dismiss-button')}
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

  if (showMachineConnectionAlert) {
    return (
      <AlertDialog open={showMachineConnectionAlert}>
        <AlertDialogContent className="max-h-1/2 p-16">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-3xl mb-4 text-center select-none" {...longPressProps}>
              {t('installation.alert-not-connected.title')}
            </AlertDialogTitle>

            <div className="flex justify-center pt-10 pb-12">
              <LoaderCircleIcon className="animate-spin h-10 w-10" />
            </div>

            <AlertDialogDescription className="text-xl tight text-center">
              {t('installation.alert-not-connected.text1')}
              <br />
              {t('installation.alert-not-connected.text2')}
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter className="mt-8">
            <AlertDialogCancel className="w-full p-8 text-md font-bold rounded-xl" asChild>
              <Link to="/">{t('installation.alert-return-home-button')}</Link>
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

  if (showEmergencyStopAlert) {
    return (
      <AlertDialog open={showEmergencyStopAlert}>
        <AlertDialogContent className="max-h-1/2 p-16">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-3xl mb-4 text-center select-none" {...longPressProps}>
              {t('installation.alert-emergency-stop.title')}
            </AlertDialogTitle>

            <div className="flex justify-center pt-10 pb-12">
              <LoaderCircleIcon className="animate-spin h-10 w-10" />
            </div>

            <AlertDialogDescription className="text-xl tight text-center">
              {t('installation.alert-emergency-stop.text')}
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter className="mt-8">
            <AlertDialogCancel className="w-full p-8 text-md font-bold rounded-xl" asChild>
              <Link to="/">{t('installation.alert-return-home-button')}</Link>
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

  if (showAlarmAlert) {
    return (
      <AlertDialog open={showAlarmAlert}>
        <AlertDialogContent
          className={cn('max-h-1/2 p-12', unhandledAlarms.length > 4 && 'max-w-[70vw] ')}
        >
          <AlertDialogHeader>
            <AlertDialogTitle className="text-3xl mb-4 select-none" {...longPressProps}>
              {t('installation.alert-alarms.title')}
            </AlertDialogTitle>
            <AlertDialogDescription className="text-lg leading-tight sr-only">
              {t('installation.alert-alarms.description', {
                alarms: unhandledAlarms.map(formatAlarmName).join(', ')
              })}
            </AlertDialogDescription>
          </AlertDialogHeader>

          <div
            className={cn(
              'grid grid-cols-1 gap-4',
              unhandledAlarms.length > 4 && 'grid grid-cols-2 gap-4'
            )}
          >
            {unhandledAlarms.map(alarm => {
              const alarmTranslationBaseKey = formatAlarmTranslationBaseKey(alarm)
              return (
                <Card className="p-1 rounded-xl pointer-events-none select-none border-red-900 bg-[#241717b3]">
                  <CardHeader className="p-4 pb-0">
                    <CardTitle className="flex items-center gap-2 text-xl">
                      <TriangleAlert className="w-6 h-6 text-red-900" />
                      <span>{t(`installation.alert-alarms.${alarmTranslationBaseKey}`)}</span>

                      {/* {t('installation.alert-alarms.dismiss-button')} */}
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="p-4 pt-2 text-foreground/80">
                    {unhandledAlarms.length < 3 && (
                      <p className="text-base">
                        {[
                          t(`installation.alert-alarms.${alarmTranslationBaseKey}-cause`),
                          t(`installation.alert-alarms.${alarmTranslationBaseKey}-actions`)
                        ].join(' ')}
                      </p>
                    )}
                  </CardContent>
                </Card>
              )
            })}
          </div>

          <AlertDialogFooter className="self-end gap-4 mt-8">
            <AlertDialogCancel
              className="w-1/2 p-8 text-md font-bold rounded-xl"
              onClick={() =>
                setDismissedAlarms([...new Set([...dismissedAlarms, ...unhandledAlarms])])
              }
            >
              {t('installation.alert-alarms.dismiss-button')}
            </AlertDialogCancel>
            <AlertDialogAction
              className="w-1/2 p-8 text-md font-bold rounded-xl"
              onClick={() => {
                setDismissedAlarms([]) // clear dangling state
                sendMachineAction({ in: 'alarms_reset' })
              }}
            >
              {t('installation.alert-alarms.reset-alarms-button')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

  return null
}
