import { useState } from 'react'
import { Button } from '@uv/ui/components/ui/button'
import { useTranslation } from 'react-i18next'
import { PreSetup } from './pre-setup'
import { InstallationSetupForm } from './setup-form'

import { ArrowLeftIcon } from 'lucide-react'
import { Link } from 'react-router-dom'
import {
  ChemistryOption,
  InstallationRecipe,
  InstallerOption,
  LinerOption,
  LinerTypeOption,
  ProjectOption,
  generateId
} from '@uv/machine'
import { TopBarInfoIcons } from '../../shared/top-bar'

export type InstallationSetupInput = {
  id: string
  preparedInstallationId?: string
  name: string
  installerId: string
  installerName: string
  projectId: string
  projectName: string
  linerType: string
  linerId: string
  linerName: string
  linerBatchNumber: string
  chemistryType: string
  chemistryId: string
  chemistryName: string
  chemistryBatchNumber: string
  distance: string
  centered?: boolean
  opened?: boolean
}

export type InstallationCustomOptions = Partial<{
  installer: InstallerOption
  project: ProjectOption
  liner: LinerOption
  linerType: LinerTypeOption
  chemistry: ChemistryOption
}>

const generateBlakFormInput = () => ({
  id: generateId('installation'),
  preparedInstallationId: undefined,
  name: '',
  installerId: '',
  installerName: '',
  projectId: '',
  projectName: '',
  linerType: '',
  linerId: '',
  linerName: '',
  linerBatchNumber: '',
  chemistryType: '',
  chemistryId: '',
  chemistryName: '',
  chemistryBatchNumber: '',
  distance: '',
  centered: undefined,
  opened: undefined
})

export const InstallationStage1Setup = () => {
  const { t } = useTranslation()
  const [isPreSetup, setIsPreSetup] = useState(true)
  const [customOptions, setCustomOptions] = useState<InstallationCustomOptions | null>(null)
  const [formInput, setFormInput] = useState<InstallationSetupInput>(generateBlakFormInput())
  const [customRecipe, setCustomRecipe] = useState<{
    recipe: InstallationRecipe
    linerId: string
    chemistryId: string
    centered: boolean
    opened: boolean
  } | null>(null)

  // Similarly to useState, allows to pass a function that receives the previous state.
  // But it also allows to pass a partial state object.
  const updateFormInput = (
    updater:
      | ((prev: InstallationSetupInput) => Partial<InstallationSetupInput>)
      | Partial<InstallationSetupInput>
  ) => {
    setFormInput(prev => {
      const updated = typeof updater === 'function' ? updater(prev) : updater
      const newState = {
        ...prev,
        ...updated
      }

      if (
        customRecipe &&
        ((updated.linerId !== undefined && updated.linerId !== customRecipe.linerId) ||
          (updated.chemistryId !== undefined && updated.chemistryId !== customRecipe.chemistryId) ||
          (updated.centered !== undefined && updated.centered !== customRecipe.centered) ||
          (updated.opened !== undefined && updated.opened !== customRecipe.opened))
      ) {
        setCustomRecipe(null)
      }

      return newState
    })
  }

  const completePreSetup = (
    input: (Partial<InstallationSetupInput> & { recipe?: InstallationRecipe }) | null
  ) => {
    if (input) {
      const { recipe, ...rest } = input
      updateFormInput(rest)

      if (recipe) {
        setCustomRecipe({
          recipe,
          linerId: rest.linerId ?? '',
          chemistryId: rest.chemistryId ?? '',
          centered: rest.centered ?? false,
          opened: rest.opened ?? false
        })
      }

      const installerOption =
        rest?.installerId && rest?.installerName
          ? { id: rest.installerId, name: rest.installerName }
          : undefined

      const projectOption =
        rest?.projectId && rest?.projectName
          ? { id: rest.projectId, name: rest.projectName }
          : undefined

      const linerOption =
        rest?.linerId && rest?.linerType
          ? { id: rest.linerId, name: rest.linerId, type: rest.linerType, reference: '' }
          : undefined

      const linerTypeOption =
        rest?.linerId && rest?.linerType ? { id: rest.linerType, name: rest.linerType } : undefined

      const chemistryOption = rest?.chemistryId
        ? { id: rest.chemistryId, name: rest.chemistryId, reference: '' }
        : undefined

      setCustomOptions({
        installer: installerOption,
        project: projectOption,
        liner: linerOption,
        linerType: linerTypeOption,
        chemistry: chemistryOption
      })
    }

    setIsPreSetup(false)
  }

  return (
    <section className="h-screen w-screen">
      <div className="flex justify-between mt-4 mx-8 mb-2">
        <div>
          {isPreSetup ? (
            <Link
              to="/"
              className="h-auto text-lg py-3 px-6 flex justify-center items-center gap-2 text-foreground bg-zinc-800/70 rounded-2xl hover:bg-zinc-800"
            >
              <ArrowLeftIcon className="w-4" />
              {t('installation.back-button')}
            </Link>
          ) : (
            <Button
              className="h-auto text-lg font-normal py-3 px-6 flex justify-center items-center gap-2 text-foreground bg-zinc-800/70 rounded-2xl hover:bg-zinc-800"
              onClick={() => {
                setFormInput(generateBlakFormInput())
                setCustomRecipe(null)
                setCustomOptions(null)
                setIsPreSetup(true)
              }}
            >
              <ArrowLeftIcon className="w-4" />
              {t('installation.back-button')}
            </Button>
          )}
        </div>

        <TopBarInfoIcons />
      </div>

      {isPreSetup ? (
        <PreSetup completePreSetup={completePreSetup} />
      ) : (
        <InstallationSetupForm
          formInput={formInput}
          updateFormInput={updateFormInput}
          customRecipe={customRecipe?.recipe || null}
          customOptions={customOptions}
        />
      )}
    </section>
  )
}
