import { z } from 'zod'

////////////////////////////////
// AUTH
////////////////////////////////

export const orgUserRoles = ['INSTALLER', 'MANAGER'] as const
export type OrgUserRole = (typeof orgUserRoles)[number]
export const uvUserRoles = ['UV_USER', 'UV_ADMIN'] as const
export type UvUserRole = (typeof uvUserRoles)[number]
export const userRoles = [...orgUserRoles, ...uvUserRoles] as const
export type UserRole = (typeof userRoles)[number]

export const UserRoleSchema = z.enum(['INSTALLER', 'MANAGER', 'UV_USER', 'UV_ADMIN'], {
  required_error: 'Role is required'
})

export const PublicUserSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  orgId: z.string(),
  role: UserRoleSchema
})
export type PublicUser = z.infer<typeof PublicUserSchema>

export const LoginInputSchema = z.object({
  email: z
    .string({ required_error: 'Email is required' })
    .min(1, 'Email is required')
    .email('Invalid email'),
  password: z.string({ required_error: 'Password is required' }).min(1, 'Password is required'),
  deviceId: z.string().optional()
})
export type LoginInput = z.infer<typeof LoginInputSchema>

export const ApiLoginInputSchema = z.object({
  email: z
    .string({ required_error: 'Email is required' })
    .min(1, 'Email is required')
    .email('Invalid email'),
  password: z.string({ required_error: 'Password is required' }).min(1, 'Password is required'),
  deviceId: z.string()
})
export type ApiLoginInput = z.infer<typeof LoginInputSchema>

export const LoginOutputSchema = z.object({
  accessToken: z.string(),
  user: PublicUserSchema
})
export type LoginOutput = z.infer<typeof LoginOutputSchema>

/////////////////////
// Jwt tokens
/////////////////////

export const JWTPayloadSchema = PublicUserSchema.extend({
  iat: z.number(),
  exp: z.number()
})
export type JWTPayload = z.infer<typeof JWTPayloadSchema>

// Define the schema for TokenUser
export const TokenUserSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string().email(),
  orgId: z.string(),
  role: UserRoleSchema
})
export type TokenUser = z.infer<typeof TokenUserSchema>

export const RefreshTokenInputSchema = z.object({
  refreshToken: z.string(),
  deviceId: z.string()
})
export type RefreshTokenInput = z.infer<typeof RefreshTokenInputSchema>

export const RefreshTokenOutputSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
  expiresIn: z.number()
})
export type RefreshTokenOutput = z.infer<typeof RefreshTokenOutputSchema>

////////////////////////////////
// UTILS
////////////////////////////////

const FactorSchema = z.number().min(0).max(100)

////////////////////////////////
// Installation Stage
////////////////////////////////

const InstallationStageSchema = z.union([
  z.literal('setup'),
  z.literal('inversion'),
  z.literal('curing'),
  z.literal('summary')
])
export type InstallationStage = z.infer<typeof InstallationStageSchema>

const InstallationStatusSchema = z.union([
  z.literal('INSTALLATION_STARTED'),
  z.literal('INSTALLATION_FINISHED'),
  z.literal('UPLOADING'),
  z.literal('UPLOAD_SUCCESS')
])
export type InstallationStatus = z.infer<typeof InstallationStatusSchema>

////////////////////////////////
// Machine System Data
////////////////////////////////
export const MachineSystemDataSchema = z.object({
  SW_VERSION: z.string(),
  SYSTEM_ID: z.string(),
  CONFIGURATION: z.object({ rpm_factor: z.number(), slip_factor: z.number() }),
  CONFIGURATION_NEW: z.boolean(),
  TS: z.string(),
  RECORDING: z.boolean(),
  ESTOP_RESET: z.boolean(),
  WATCHDOG: z.boolean(),
  WATCHDOG_TOGGLE: z.boolean(),
  WATCHDOG_PERM: z.number(),
  ROD_RESET: z.boolean(),
  ALARMS_RESET: z.boolean(),
  ROD_SERVICE: z.boolean(),
  UV_SERVICE: z.boolean(),
  LAMP: z.number(),
  WATCHDOG_OK: z.boolean(),
  ESTOP_OK: z.boolean(),
  ALARMS: z.tuple([
    z.boolean(), // ALARM_UV_HOT
    z.boolean(), // ALARM_AIR_HOT
    z.boolean(), // ALARM_MOTOR
    z.boolean(), // ALARM_AIRSUPPLY
    z.boolean(), // ALARM_HEAD
    z.boolean(), // ALARM_PRESSURE
    z.boolean(), // ALARM_SLIP
    z.boolean() // ALARM_SENSOR
  ]),
  OVERRIDES: z.tuple([
    z.boolean(), // ALARM_UV_HOT
    z.boolean(), // ALARM_AIR_HOT
    z.boolean(), // ALARM_MOTOR
    z.boolean(), // ALARM_AIRSUPPLY
    z.boolean(), // ALARM_HEAD
    z.boolean(), // ALARM_PRESSURE
    z.boolean(), // ALARM_SLIP
    z.boolean() // ALARM_SENSOR
  ]),
  OVERRIDES_NEW: z.boolean(),
  UV_CURRENTS: z.tuple([z.number(), z.number()]),
  AIR_PRESSURE: z.number(),
  AIR_TEMP: z.number(),
  DISTANCE: z.number(),
  SLIP: z.number(),
  ROD_NEW: z.tuple([z.number(), z.number()]),
  RPM: z.number(),
  UV_TEMP: z.tuple([z.number(), z.number(), z.number(), z.number(), z.number()]),
  // UV_TEMP_FAST: z.number(),
  PLC_VERSION: z.string(),
  HEAD_ID: z.tuple([z.number(), z.number(), z.number(), z.number()]),
  RPM_FACTORS: z.tuple([z.number(), z.number(), z.number()]),
  RPM_LIST: z.tuple([
    z.number().describe('Max Speed (m/hr)'), // Max Speed (m/hr)
    z.array(
      z.tuple([
        z.number().describe('Distance (m)'), // Distance (m)
        z.number().describe('Factor (%)') // Factor (%)
      ])
    )
  ]),
  RPM_POSITIVE: z.boolean(),
  RPM_LIST_NEW: z.boolean(),
  UV_CYCLE: z.tuple([
    z.number(), // Max Power (% of machine max)
    z.array(
      z.tuple([
        z.number(), // Time Seconds (s)
        z.number() // Factor (%)
      ])
    )
  ]),
  UV_CICLE_NEW: z.boolean(),
  UV_FACTORS: z.tuple([z.number(), z.number(), z.number()]),
  STORAGE_FREE: z.number(),
  LIMP: z.number(),
  // TODO: Event (None)
  RPM_ADJUST: z.number(),
  TABLET_CMDCOUNT: z.object({ POWEROFF: z.number(), REBOOT: z.number(), REKIOSK: z.number() }),
  TABLET_NETIDENT: z.string(),
  TABLET_BATTERY: z.tuple([z.boolean(), z.number()]),
  // TABLET_FIREWALL: z.null() // (None)
  WIFI_STRENGTH: z.number(),
  DATETIME_CHANGED: z.boolean()
})
export type MachineSystemData = z.infer<typeof MachineSystemDataSchema>

const RecipeParametersSchema = z.object({
  maxSpeed: z.number(),
  speeds: z.array(
    z.object({
      distance: z.number(),
      factor: z.number()
    })
  ),
  maxPower: z.number(),
  powers: z.array(
    z.object({
      duration: z.number(),
      factor: z.number()
    })
  ),
  delay: z.number()
})
export type RecipeParameters = z.infer<typeof RecipeParametersSchema>

export const InstallationRecipeSchema = RecipeParametersSchema.extend({
  id: z.string().optional()
})
export type InstallationRecipe = z.infer<typeof InstallationRecipeSchema>

export const InstallationInfoSchema = z.object({
  machineId: z.string(),
  id: z.string(),
  preparedInstallationId: z.string().optional(),
  startedAt: z.string(),
  name: z.string().min(1, { message: 'Required' }),
  installerId: z.string().min(1, { message: 'Required' }),
  installerName: z.string().min(1, { message: 'Required' }),
  projectId: z.string().optional(),
  projectName: z.string().optional(),
  linerId: z.string().optional(),
  linerName: z.string().optional(),
  linerBatchNumber: z.string().optional(),
  chemistryId: z.string().optional(),
  chemistryName: z.string().optional(),
  chemistryBatchNumber: z.string().optional(),
  centered: z.boolean().optional(),
  opened: z.boolean().optional(),
  distance: z.number().min(0.1, { message: 'Required' }),
  recipe: InstallationRecipeSchema
})
export type InstallationInfo = z.infer<typeof InstallationInfoSchema>

export const InstallationFileMetaSchema = z.object({
  id: z.string(),
  name: z.string(), // friendly name to refer to the installation. Required by the websocket server
  machineId: z.string(),
  installationInfo: InstallationInfoSchema
})
export type InstallationFileMeta = z.infer<typeof InstallationFileMetaSchema>

export const VideoFileMetaSchema = z.object({
  duration: z.number(), // duration in seconds
  framerate: z.number(), // framerate in hz
  first: z.number(), // first timecode in file
  last: z.number(), // last timecode in file
  clean: z.boolean() // file contains only full frames
})
export type VideoFileMeta = z.infer<typeof VideoFileMetaSchema>

export const InstallationFileMeasurementKeysSchema = z
  .union([
    z.literal('TS'),
    z.literal('ROD_SERVICE'),
    z.literal('UV_SERVICE'),
    z.literal('LAMP'),
    z.literal('ESTOP_OK'),
    z.literal('WATCHDOG_OK'),
    z.literal('ALARMS'),
    z.literal('UV_CURRENTS'),
    z.literal('AIR_PRESSURE'),
    z.literal('AIR_TEMP'),
    z.literal('DISTANCE'),
    z.literal('SLIP'),
    z.literal('RPM'),
    z.literal('RPM_FACTORS'),
    z.literal('UV_TEMP'),
    z.literal('UV_FACTORS')
  ])
  .array()
export type InstallationFileMeasurementKeys = z.infer<typeof InstallationFileMeasurementKeysSchema>

export const InstallationFileMeasurementSchema = z
  .union([
    MachineSystemDataSchema.shape.TS,
    MachineSystemDataSchema.shape.ROD_SERVICE,
    MachineSystemDataSchema.shape.UV_SERVICE,
    MachineSystemDataSchema.shape.LAMP,
    MachineSystemDataSchema.shape.ESTOP_OK,
    MachineSystemDataSchema.shape.WATCHDOG_OK,
    MachineSystemDataSchema.shape.ALARMS,
    MachineSystemDataSchema.shape.UV_CURRENTS,
    MachineSystemDataSchema.shape.AIR_PRESSURE,
    MachineSystemDataSchema.shape.AIR_TEMP,
    MachineSystemDataSchema.shape.DISTANCE,
    MachineSystemDataSchema.shape.SLIP,
    MachineSystemDataSchema.shape.RPM,
    MachineSystemDataSchema.shape.RPM_FACTORS,
    MachineSystemDataSchema.shape.UV_TEMP,
    MachineSystemDataSchema.shape.UV_FACTORS
  ])
  .array()
export type InstallationFileMeasurement = z.infer<typeof InstallationFileMeasurementSchema>

export const InstallationFileEventSchema = z.discriminatedUnion('event', [
  z.object({
    event: z.literal('recover'),
    TS: z.string()
  }),
  z.object({
    event: z.literal('stop'),
    TS: z.string()
  }),
  z.object({
    event: z.literal('meta'),
    TS: z.string()
  }),
  z.object({
    event: z.literal('settings'),
    TS: z.string(),
    rpm_configure: MachineSystemDataSchema.shape.RPM_LIST.optional(),
    uv_configure: MachineSystemDataSchema.shape.UV_CYCLE.optional(),
    overrides_configure: MachineSystemDataSchema.shape.OVERRIDES.optional()
  }),
  z.object({
    event: z.literal('configuration'),
    TS: z.string()
  })
])
export type InstallationFileEvent = z.infer<typeof InstallationFileEventSchema>
export const isInstallationFileEvent = (
  installationFileEntry: InstallationFileEntry
): installationFileEntry is InstallationFileEvent => {
  return 'event' in installationFileEntry
}

export const InstallationFileEntrySchema = InstallationFileMeasurementSchema.or(
  InstallationFileEventSchema
)
export type InstallationFileEntry = z.infer<typeof InstallationFileEntrySchema>

export const InstallationFileSchema = z.object({
  fileMeta: InstallationFileMetaSchema,
  keys: InstallationFileMeasurementKeysSchema,
  entries: z.array(InstallationFileEntrySchema)
})
export type InstallationFile = z.infer<typeof InstallationFileSchema>

// type MachineInMessageKey = Extract<MachineInMessage, { in: string }>['in']

export const InstallationCustomEventSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('screenshot'),
    timestamp: z.string()
  })
])

export const MachineLocalStoreSchema = z.object({
  installationStage: InstallationStageSchema
})

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
// MESSAGES
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////

//////////////////////////////
// IN
//////////////////////////////
export const MachineInMessageSchema = z.discriminatedUnion('in', [
  z.object({
    in: z.literal('hello')
  }),
  z.object({
    id: z.string(),
    in: z.literal('ping'),
    payload: z.literal('hey')
  }),
  z.object({
    id: z.string(),
    in: z.literal('refresh'),
    watchdog: z.number()
  }),
  // Dashboard
  z.object({
    id: z.string(),
    in: z.literal('uv_service_toggle')
  }),
  z.object({
    id: z.string(),
    in: z.literal('rod_service_toggle')
  }),
  z.object({
    id: z.string(),
    in: z.literal('uv_set'),
    factor: FactorSchema
  }),
  z.object({
    id: z.string(),
    in: z.literal('lamp_set'),
    factor: FactorSchema
  }),
  z.object({
    id: z.string(),
    in: z.literal('alarms_reset')
  }),
  z.object({
    id: z.string(),
    in: z.literal('rod_reset'),
    distance: z.number(),
    slip: z.number(),
  }),
  z.object({
    id: z.string(),
    in: z.literal('estop_reset')
  }),
  z.object({
    id: z.string(),
    in: z.literal('rpm_set'),
    factor: FactorSchema
  }),
  // Files
  z.object({
    id: z.string(),
    in: z.literal('file_start'),
    meta: InstallationFileMetaSchema
  }),
  z.object({
    id: z.string(),
    in: z.literal('file_stop')
  }),
  z.object({
    id: z.string(),
    in: z.literal('file_list')
  }),
  z.object({
    id: z.string(),
    in: z.literal('file_read'),
    offset: z.number(),
    count: z.number(),
    file: z.literal('active.json').or(z.string())
  }),
  z.object({
    id: z.string(),
    in: z.literal('file_write'),
    file: z.string(),
    payload: z.string()
  }),
  z.object({
    id: z.string(),
    in: z.literal('file_remove'),
    file: z.string()
  }),
  z.object({
    id: z.string(),
    in: z.literal('file_meta'),
    file: z.literal('active.json').or(z.string())
  }),
  z.object({
    id: z.string(),
    in: z.literal('file_event'),
    meta: InstallationCustomEventSchema
  }),
  // Configuration
  z.object({
    id: z.string(),
    in: z.literal('configuration_get')
  }),
  z.object({
    id: z.string(),
    in: z.literal('overrides_configure'),
    overrides: MachineSystemDataSchema.shape.OVERRIDES
  }),
  z.object({
    id: z.string(),
    in: z.literal('rpm_configure'),
    list: MachineSystemDataSchema.shape.RPM_LIST
  }),
  z.object({
    id: z.string(),
    in: z.literal('uv_configure'),
    cycle: MachineSystemDataSchema.shape.UV_CYCLE
  }),
  z.object({
    id: z.string(),
    in: z.literal('head_id')
  }),
  z.object({
    id: z.string(),
    in: z.literal('storage')
  }),
  z.object({
    id: z.string(),
    in: z.literal('localstore_set'),
    meta: MachineLocalStoreSchema.nullable()
  }),
  z.object({
    id: z.string(),
    in: z.literal('localstore_get')
  }),
  z.object({
    id: z.string(),
    in: z.literal('machine_configuration'),
    data: MachineSystemDataSchema.shape.CONFIGURATION.partial() // Accepts individual fields
  }),
  z.object({
    id: z.string(),
    in: z.literal('datetime_set'),
    ts: MachineSystemDataSchema.shape.TS
  }),
  z.object({
    id: z.string(),
    in: z.literal('tablet_control'),
    command: z.union([z.literal('POWEROFF'), z.literal('REBOOT'), z.literal('REKIOSK')]) // TODO: infer it from MachineSystemDataSchema.shape.TABLET_CMDCOUNT
  }),
  z.object({
    id: z.string(),
    in: z.literal('tablet_firewall'),
    remoteip: z.string()
  })
])
export type MachineInMessage = z.infer<typeof MachineInMessageSchema>

//////////////////////////////
// OUT
//////////////////////////////

const MachineRefreshDataSchema = MachineSystemDataSchema.pick({
  TS: true,
  ROD_SERVICE: true,
  UV_SERVICE: true,
  UV_FACTORS: true,
  LAMP: true,
  ESTOP_OK: true,
  ALARMS: true,
  UV_CURRENTS: true,
  AIR_PRESSURE: true,
  AIR_TEMP: true,
  DISTANCE: true,
  SLIP: true,
  RPM: true,
  RPM_FACTORS: true,
  UV_TEMP: true,
  // UV_TEMP_FAST: true,
  PLC_VERSION: true,
  SW_VERSION: true,
  RECORDING: true
}).extend({
  WATCHDOG: MachineSystemDataSchema.shape.WATCHDOG_PERM,
  WATCHDOG_OK: z.boolean(),
  LIMP: z.boolean(),
  NETIDENT: MachineSystemDataSchema.shape.TABLET_NETIDENT,
  BATTERY: MachineSystemDataSchema.shape.TABLET_BATTERY,
  WIFI: MachineSystemDataSchema.shape.WIFI_STRENGTH
})
export type RefreshData = z.infer<typeof MachineRefreshDataSchema>

export const MachineOutMessageSchema = z.discriminatedUnion('out', [
  z.object({
    id: z.string(),
    out: z.literal('id')
  }),
  z.object({
    id: z.string(),
    out: z.literal('pong'),
    payload: z.literal('hey')
  }),
  z
    .object({
      id: z.string(),
      out: z.literal('refresh')
    })
    .merge(MachineRefreshDataSchema),
  z.object({
    id: z.string(),
    out: z.literal('uv_service_toggle')
  }),
  z.object({
    id: z.string(),
    out: z.literal('rod_service_toggle')
  }),
  z.object({
    id: z.string(),
    out: z.literal('uv_set')
  }),
  z.object({
    id: z.string(),
    out: z.literal('lamp_set')
  }),
  z.object({
    id: z.string(),
    out: z.literal('alarms_reset')
  }),
  z.object({
    id: z.string(),
    out: z.literal('rod_reset')
  }),
  z.object({
    id: z.string(),
    out: z.literal('estop_reset')
  }),
  z.object({
    id: z.string(),
    out: z.literal('rpm_set')
  }),
  // Files
  z.object({
    id: z.string(),
    out: z.literal('file_start'),
    payload: MachineSystemDataSchema.shape.RECORDING
  }),
  z.object({
    id: z.string(),
    out: z.literal('file_stop')
  }),
  z.object({
    id: z.string(),
    out: z.literal('file_list'),
    files: z.array(z.string())
  }),
  z.object({
    id: z.string(),
    out: z.literal('file_read'),
    file: z.string(),
    offset: z.number(),
    payload: z.string()
  }),
  z.object({
    id: z.string(),
    out: z.literal('file_write'),
    file: z.string()
  }),
  z.object({
    id: z.string(),
    out: z.literal('file_remove'),
    file: z.string()
  }),
  z.object({
    id: z.string(),
    out: z.literal('file_meta'),
    file: z.string(),
    payload: z
      .union([
        InstallationFileMetaSchema, // Installation Files
        VideoFileMetaSchema // Video Files
      ])
      .or(z.null())
  }),
  z.object({
    id: z.string(),
    out: z.literal('file_event')
  }),
  // Configuration Setup
  z.object({
    id: z.string(),
    out: z.literal('configuration_get'),
    payload: z.object({
      rpm_list: MachineSystemDataSchema.shape.RPM_LIST,
      uv_cycle: MachineSystemDataSchema.shape.UV_CYCLE,
      overrides: MachineSystemDataSchema.shape.OVERRIDES
    })
  }),
  z.object({
    id: z.string(),
    out: z.literal('overrides_configure'),
    overrides: MachineSystemDataSchema.shape.OVERRIDES
  }),
  z.object({
    id: z.string(),
    out: z.literal('rpm_configure'),
    list: MachineSystemDataSchema.shape.RPM_LIST
  }),
  z.object({
    id: z.string(),
    out: z.literal('uv_configure'),
    cycle: MachineSystemDataSchema.shape.UV_CYCLE
  }),
  z.object({
    id: z.string(),
    out: z.literal('head_id'),
    headid: MachineSystemDataSchema.shape.HEAD_ID
  }),
  z.object({
    id: z.string(),
    out: z.literal('storage'),
    free: MachineSystemDataSchema.shape.STORAGE_FREE,
    limp: MachineSystemDataSchema.shape.LIMP
  }),
  z.object({
    id: z.string(),
    out: z.literal('localstore_set')
  }),
  z.object({
    id: z.string(),
    out: z.literal('localstore_get'),
    meta: MachineLocalStoreSchema.nullable()
  }),
  z.object({
    id: z.string(),
    out: z.literal('machine_configuration'),
    meta: MachineSystemDataSchema.shape.CONFIGURATION
  }),
  z.object({
    id: z.string(),
    out: z.literal('datetime_set')
  }),
  z.object({
    id: z.string(),
    out: z.literal('tablet_control')
  }),
  z.object({
    id: z.string(),
    out: z.literal('tablet_firewall')
  }),
  z.object({
    id: z.string(),
    out: z.literal('error'),
    error: z.string()
  })
])
export type MachineOutMessage = z.infer<typeof MachineOutMessageSchema>

export const isInstallationFileMeta = (
  fileMetaPayload: Extract<MachineOutMessage, { out: 'file_meta' }>['payload']
): fileMetaPayload is InstallationFileMeta => {
  return (
    fileMetaPayload !== null &&
    typeof fileMetaPayload === 'object' &&
    'installationInfo' in fileMetaPayload
  )
}

export const isVideoFileMeta = (
  fileMetaPayload: Extract<MachineOutMessage, { out: 'file_meta' }>['payload']
): fileMetaPayload is VideoFileMeta => {
  return fileMetaPayload !== null && fileMetaPayload !== undefined && 'duration' in fileMetaPayload
}

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
// VIDEO
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////

// { type: "rec_start", data: { meta_name: "same style name as used with /json" } }
// { type: "rec_stop", data: null }

export const MachineVideoInMessageSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('rec_start'),
    data: z.object({
      meta_name: z.string()
    })
  }),
  z.object({
    type: z.literal('rec_stop'),
    data: z.null()
  })
])
export type MachineVideoInMessage = z.infer<typeof MachineVideoInMessageSchema>

export const MachineVideoOutMessageSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('rec'),
    data: z.string().nullable()
  })
])
export type MachineVideoOutMessage = z.infer<typeof MachineVideoOutMessageSchema>

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
// MACHINE
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////

export const MachineConnectionStatusSchema = z.union([
  z.literal('CONNECTING'),
  z.literal('CONNECTED'),
  z.literal('DISCONNECTING'),
  z.literal('DISCONNECTED'),
  z.literal('UNINSTANTIATED')
])
export type MachineConnectionStatus = z.infer<typeof MachineConnectionStatusSchema>

export const machineAlarms = [
  'UV_HOT',
  'AIR_HOT',
  'MOTOR',
  'AIRSUPPLY',
  'HEAD',
  'PRESSURE',
  'SLIP',
  'SENSOR'
] as const
export type MachineAlarm = (typeof machineAlarms)[number]

export const dismissableMachineAlarms = [...machineAlarms, 'WIFI'] as const
export type DismissableMachineAlarm = (typeof dismissableMachineAlarms)[number]

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
// ACTIVE INSTALLATION
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////

export const ActiveInstallationRefreshMetricsSchema = z.object({
  prevTime: z.number(),
  delta: z.number(),
  peak: z.number(),
  count: z.number(),
  slowTimes: z.array(z.number())
})
export type ActiveInstallationRefreshMetrics = z.infer<
  typeof ActiveInstallationRefreshMetricsSchema
>

export const ActiveInstallationSampleSchema = z.object({
  timestamp: z.number(),
  speed: z.number(),
  pressure: z.number(),
  temperature: z.number()
})
export type ActiveInstallationSamples = z.infer<typeof ActiveInstallationSampleSchema>

export const ActiveInstallationSchema = z.object({
  stage: InstallationStageSchema,
  fileMeta: InstallationFileMetaSchema,
  finishedAt: z.string().optional(),
  samples: ActiveInstallationSampleSchema.array()
})
export type ActiveInstallation = z.infer<typeof ActiveInstallationSchema>

export const MachineSchema = z.object({
  machineId: z.string().nullable(),
  connectionStatus: MachineConnectionStatusSchema,
  refreshMetrics: ActiveInstallationRefreshMetricsSchema,
  refreshData: MachineRefreshDataSchema.nullable(),
  alarmOverrides: MachineSystemDataSchema.shape.OVERRIDES
})
export type Machine = z.infer<typeof MachineSchema>

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
// Installation Files and Installation Records
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////

export const InstallationSchema = z.object({
  id: z.string(),
  fileName: z.string(),
  machineId: z.string(),
  installationTimestamp: z.string(),
  status: InstallationStatusSchema,
  file: InstallationFileSchema
})
export type Installation = z.infer<typeof InstallationSchema>

export const LocalInstallationRecordSchema = InstallationInfoSchema.extend({
  finishedAt: z.string()
})
export type LocalInstallationRecord = z.infer<typeof LocalInstallationRecordSchema>

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
// INSTALLATION OPTIONS
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////

const PreparedInstallationOptionSchema = z.object({
  id: z.string(),
  name: z.string(),
  installationInfo: z.object({
    installationName: InstallationInfoSchema.shape.name,
    installerId: InstallationInfoSchema.shape.installerId.optional(),
    installerName: InstallationInfoSchema.shape.installerName.optional(),
    projectId: InstallationInfoSchema.shape.projectId.optional(),
    projectName: InstallationInfoSchema.shape.projectName.optional(),
    linerType: z.string(),
    linerId: InstallationInfoSchema.shape.linerId.unwrap(),
    linerName: InstallationInfoSchema.shape.linerName,
    linerBatchNumber: InstallationInfoSchema.shape.linerBatchNumber,
    chemistryId: InstallationInfoSchema.shape.chemistryId.unwrap(),
    chemistryName: InstallationInfoSchema.shape.chemistryName,
    chemistryBatchNumber: InstallationInfoSchema.shape.chemistryBatchNumber,
    distance: InstallationInfoSchema.shape.distance.optional(),
    centered: InstallationInfoSchema.shape.centered,
    opened: InstallationInfoSchema.shape.opened
  })
})
export type PreparedInstallationOption = z.infer<typeof PreparedInstallationOptionSchema>

const InstallerOptionSchema = z.object({
  id: z.string(),
  name: z.string()
})
export type InstallerOption = z.infer<typeof InstallerOptionSchema>

const LinerTypeOptionSchema = z.object({
  id: z.string(),
  name: z.string()
})
export type LinerTypeOption = z.infer<typeof LinerTypeOptionSchema>

const LinerOptionSchema = z.object({
  id: z.string(),
  reference: z.string(),
  type: z.string(),
  name: z.string()
})
export type LinerOption = z.infer<typeof LinerOptionSchema>

const ChemistryOptionSchema = z.object({
  id: z.string(),
  reference: z.string(),
  name: z.string()
})
export type ChemistryOption = z.infer<typeof ChemistryOptionSchema>

const ProjectOptionSchema = z.object({
  id: z.string(),
  name: z.string()
})
export type ProjectOption = z.infer<typeof ProjectOptionSchema>

const RecipeOptionSchema = z.object({
  id: z.string(),
  linerId: z.string(),
  chemistryId: z.string(),
  notCenteredOpened: RecipeParametersSchema.extend({
    centered: z.literal(false),
    opened: z.literal(true)
  }), // required
  notCenteredClosed: RecipeParametersSchema.extend({
    centered: z.literal(false),
    opened: z.literal(false)
  }).nullable(), // required
  centeredClosed: RecipeParametersSchema.extend({
    centered: z.literal(true),
    opened: z.literal(false)
  }).nullable(),
  centeredOpened: RecipeParametersSchema.extend({
    centered: z.literal(true),
    opened: z.literal(true)
  }).nullable()
})
export type RecipeOption = z.infer<typeof RecipeOptionSchema>

const InstallationOptionsSchema = z.object({
  preparedInstallations: PreparedInstallationOptionSchema.array(),
  installers: InstallerOptionSchema.array(),
  linerTypes: LinerTypeOptionSchema.array(),
  liners: LinerOptionSchema.array(),
  chemistries: ChemistryOptionSchema.array(),
  recipes: RecipeOptionSchema.array(),
  projects: ProjectOptionSchema.array()
})
export type InstallationOptions = z.infer<typeof InstallationOptionsSchema>

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
// QR
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
export const InstallationQrCodeSchema = z.object({
  isCustom: z.boolean().optional(),
  installationName: InstallationInfoSchema.shape.name.optional(),
  installerId: InstallationInfoSchema.shape.installerId.optional(),
  installerName: InstallationInfoSchema.shape.installerName.optional(),
  projectId: InstallationInfoSchema.shape.projectId.optional(),
  projectName: InstallationInfoSchema.shape.projectName.optional(),
  linerType: z.string(),
  linerId: InstallationInfoSchema.shape.linerId,
  linerName: InstallationInfoSchema.shape.linerName,
  linerBatchNumber: InstallationInfoSchema.shape.linerBatchNumber,
  chemistryId: InstallationInfoSchema.shape.chemistryId,
  chemistryName: InstallationInfoSchema.shape.chemistryName,
  chemistryBatchNumber: InstallationInfoSchema.shape.chemistryBatchNumber,
  distance: InstallationInfoSchema.shape.distance.optional(),
  centered: InstallationInfoSchema.shape.centered.optional(),
  opened: InstallationInfoSchema.shape.opened.optional(),
  recipe: InstallationRecipeSchema.optional()
})

export type InstallationQrCode = z.infer<typeof InstallationQrCodeSchema>

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
// SYNC SERVER
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////

export const MachineSeenSchema = z.object({
  id: z.string(),
  lastSeen: z.string().datetime(),
  lastSync: z.string().nullable()
})
export type MachineSeen = z.infer<typeof MachineSeenSchema>

export const CloudSchema = z.object({
  lastSync: z.string().datetime().nullable()
  // machineFirmware: z.string().optional(),
})
export type Cloud = z.infer<typeof CloudSchema>

export const RecentInstallationsSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    status: z.string(),
    timestamp: z.string(),
    project: z.object({
      id: z.string(),
      name: z.string()
    })
  })
  .array()
export type RecentInstallations = z.infer<typeof RecentInstallationsSchema>

// FETCH OPTIONS
export const SyncServerStateInputSchema = z.object({
  machinesSeen: MachineSeenSchema.array()
})
export type SyncServerStateInput = z.infer<typeof SyncServerStateInputSchema>

export const SyncServerStateOutputSchema = z.object({
  updatedOn: z.string().datetime(),
  installationOptions: InstallationOptionsSchema
})
export type SyncServerStateOutput = z.infer<typeof SyncServerStateOutputSchema>

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
// SYNC SERVER INSTALLATIONS
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////

export const CloudStartInstallationInputSchema = InstallationInfoSchema
export type CloudStartInstallationInput = z.infer<typeof CloudStartInstallationInputSchema>

export const CloudFinishInstallationInputSchema = InstallationInfoSchema.extend({
  finishedAt: z.string()
})
export type CloudFinishInstallationInput = z.infer<typeof CloudFinishInstallationInputSchema>

export const CloudSyncInstallationsInputSchema = z
  .object({
    fileName: z.string(),
    hasFile: z.boolean(),
    fileMeta: InstallationFileMetaSchema.optional()
  })
  .array()
export type CloudSyncInstallationsInput = z.infer<typeof CloudSyncInstallationsInputSchema>

export const CloudSyncVideoInputSchema = z
  .object({
    fileName: z.string(),
    hasFile: z.boolean(),
    duration: z.number().optional()
  })
  .array()
export type CloudSyncVideoInput = z.infer<typeof CloudSyncVideoInputSchema>

export const CloudSyncFilesOutputSchema = z
  .discriminatedUnion('action', [
    z.object({
      action: z.literal('FILE_REMOVE'),
      fileName: z.string()
    }),
    z.object({
      action: z.literal('FILE_META'),
      fileName: z.string()
    }),
    z.object({
      action: z.literal('FILE_READ'),
      fileName: z.string()
    }),
    z.object({
      action: z.literal('UPLOAD'),
      fileName: z.string(),
      presignedUrl: z.string()
    })
  ])
  .array()
export type CloudSyncFilesOutput = z.infer<typeof CloudSyncFilesOutputSchema>

export const isUploadCloudFileOutput = (
  installation: CloudSyncFilesOutput[0]
): installation is Extract<CloudSyncFilesOutput[0], { action: 'UPLOAD' }> => {
  // return installation.status === 'UPLOADING'
  return installation.action === 'UPLOAD' && Boolean(installation.presignedUrl)
}

export const CloudSyncUploadedInstallationFileInputSchema = z.object({
  id: z.string(),
  fileUrl: z.string()
})
export type CloudSyncUploadedInstallationFileInput = z.infer<
  typeof CloudSyncUploadedInstallationFileInputSchema
>

export const CloudSyncUploadedVideoFileInputSchema = z.object({
  fileName: z.string(),
  fileUrl: z.string()
})
export type CloudSyncUploadedVideoFileInput = z.infer<typeof CloudSyncUploadedVideoFileInputSchema>

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
// LAMBDA - Extract screenshots
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////

export const LambdaInstallationVideoScreenshotsInputSchema = z.object({
  videoUrl: z.string().url(),
  timestamps: z.array(z.number())
})
export type LambdaInstallationVideoScreenshotsInput = z.infer<
  typeof LambdaInstallationVideoScreenshotsInputSchema
>

export const LambdaInstallationVideoScreenshotsOutputSchema = z
  .object({
    timestamp: z.string().url(),
    url: z.array(z.number())
  })
  .array()
export type LambdaInstallationVideoScreenshotsOutput = z.infer<
  typeof LambdaInstallationVideoScreenshotsOutputSchema
>

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
// Excel - Recipes
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////

const ExcelRecipeRowSchema = z.tuple([
  z.tuple([z.literal('id'), z.string()]),
  z.tuple([z.literal('updated'), z.date()]),
  z.tuple([z.literal('linerId'), z.string()]),
  z.tuple([z.literal('linerName'), z.string()]),
  z.tuple([z.literal('linerReference'), z.string()]),
  z.tuple([z.literal('chemistryId'), z.string()]),
  z.tuple([z.literal('chemistryName'), z.string()]),
  z.tuple([z.literal('chemistryReference'), z.string()]),
  z.tuple([z.literal('notCenteredOpenedMaxSpeed'), z.number()]),
  z.tuple([z.literal('notCenteredOpenedDelay'), z.number()]),
  z.tuple([z.literal('notCenteredOpenedDistance'), z.number()]),
  z.tuple([z.literal('notCenteredOpenedFactor'), z.number()]),
  z.tuple([z.literal('notCenteredOpenedMaxPower'), z.number()]),
  z.tuple([z.literal('notCenteredOpenedDuration1'), z.number()]),
  z.tuple([z.literal('notCenteredOpenedFactor1'), z.number()]),
  z.tuple([z.literal('notCenteredOpenedDuration2'), z.number().nullable()]),
  z.tuple([z.literal('notCenteredOpenedFactor2'), z.number().nullable()]),
  z.tuple([z.literal('notCenteredClosedMaxSpeed'), z.number().nullable()]),
  z.tuple([z.literal('notCenteredClosedDelay'), z.number().nullable()]),
  z.tuple([z.literal('notCenteredClosedDistance'), z.number().nullable()]),
  z.tuple([z.literal('notCenteredClosedFactor'), z.number().nullable()]),
  z.tuple([z.literal('notCenteredClosedMaxPower'), z.number().nullable()]),
  z.tuple([z.literal('notCenteredClosedDuration1'), z.number().nullable()]),
  z.tuple([z.literal('notCenteredClosedFactor1'), z.number().nullable()]),
  z.tuple([z.literal('notCenteredClosedDuration2'), z.number().nullable()]),
  z.tuple([z.literal('notCenteredClosedFactor2'), z.number().nullable()]),
  z.tuple([z.literal('centeredClosedMaxSpeed'), z.number().nullable()]),
  z.tuple([z.literal('centeredClosedDelay'), z.number().nullable()]),
  z.tuple([z.literal('centeredClosedDistance'), z.number().nullable()]),
  z.tuple([z.literal('centeredClosedFactor'), z.number().nullable()]),
  z.tuple([z.literal('centeredClosedMaxPower'), z.number().nullable()]),
  z.tuple([z.literal('centeredClosedDuration1'), z.number().nullable()]),
  z.tuple([z.literal('centeredClosedFactor1'), z.number().nullable()]),
  z.tuple([z.literal('centeredClosedDuration2'), z.number().nullable()]),
  z.tuple([z.literal('centeredClosedFactor2'), z.number().nullable()]),
  z.tuple([z.literal('centeredOpenedMaxSpeed'), z.number().nullable()]),
  z.tuple([z.literal('centeredOpenedDelay'), z.number().nullable()]),
  z.tuple([z.literal('centeredOpenedDistance'), z.number().nullable()]),
  z.tuple([z.literal('centeredOpenedFactor'), z.number().nullable()]),
  z.tuple([z.literal('centeredOpenedMaxPower'), z.number().nullable()]),
  z.tuple([z.literal('centeredOpenedDuration1'), z.number().nullable()]),
  z.tuple([z.literal('centeredOpenedFactor1'), z.number().nullable()]),
  z.tuple([z.literal('centeredOpenedDuration2'), z.number().nullable()]),
  z.tuple([z.literal('centeredOpenedFactor2'), z.number().nullable()])
])
export type ExcelRecipeRow = z.infer<typeof ExcelRecipeRowSchema>

export type ExcelRecipeHeader =
  typeof ExcelRecipeRowSchema extends z.ZodTuple<infer T>
    ? { [K in keyof T]: T[K] extends z.ZodTuple<[z.ZodLiteral<infer U>, any]> ? U : never }
    : never
